body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar{
  display: none;
}

/* ===== Book Recommendations Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #a8a8a8 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*:-webkit-scrollbar-track {
  background: rgba(255,255,255,0);
}

*:-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 10px;
  border: 4px solid #ffffff;
  }

.thumb{
  box-shadow: none !important;
}
.thumb::before{
  box-shadow: none !important;
}
.bar-inner,.multi-range-slider {
  box-shadow: none !important;

}
.bar-left, .bar-right {
  box-shadow: none !important;
  background-color: #8f8c98 !important;

}

.multi-range-slider .thumb .caption * {
  background-color: white !important;
  color:black !important;
}
.multi-range-slider .label {
  text-align: center;
  color: #21005D;
  font-weight: bolder;
}

.multi-range-slider .ruler .ruler-rule {
  border-bottom: none !important;
  border-color: #21005D !important;
}

.vis-item {
  background-color:white !important;
  text-align: left !important;
}

.vis-timeline {
  border: none !important;
}
.vis-box {
  border: 1.5px solid black !important;
  border-radius: 8px !important;
}
.vis-line {
  border: 1px solid black !important;
}

.vis-dot {
  /*background: black !important;*/
  border-color: black !important;

}

.vis-center {
  /*border-color: #6750A4 !important;*/
  border-bottom: 3px solid #6750A4 !important;

}

.vis-time-axis .vis-grid.vis-minor {
  /*border-width: 2px;*/
  border: none !important;
}

.vis-grid {
  border: none !important;
}

.vis-item-content {
  padding:0px !important;
  margin:0px !important;
}

/*.vis-item.img-vis-crd {*/
/*  padding:0px !important;*/
/*  background-color: magenta;*/
/*  border-color: purple;*/
/*  color: green;*/
/*}*/

.vis-item.img-vis-crd h6 {
  position: absolute;
  top:20px;
  left:20px;
  color:white;
}


.vis-item.img-vis-crd h3 {
  position: absolute;
  top:40px;
  left:20px;
  color:white;
}


.vis-item.img-vis-crd {
  max-height: 400px !important;
  overflow:hidden;
}



.vis-item.vis-note-card p {
  max-width: 15vw;
  text-wrap: wrap;
  padding:10px;
}
.vis-item.vis-note-card h6 {
  max-width: 20vw;
  text-wrap: wrap;
  border-radius: 8px 8px 0 0;
  background-color: #76baff !important;
  padding: 10px;
}
.vis-item.padding-card-vis {
  padding:10px !important;
}

.vis-item.color-top hr {
  background-color: #FF9800 !important;
  width: 100%;
  margin-top:0px;
  margin-bottom:0px;
  height: 10px;
}
.vis-item.color-top h6 {
  padding-left:10px;
  padding-top:8px
}
.vis-item.color-top p {
  padding-left:10px;
}
.vis-item.color-top h3 {
  padding-left:10px;
}

.test-button {
  /*box-shadow: 2px 2px 2px 0px #08080840 inset;*/
  box-shadow: -2px -2px 2px 0px #CACACA40 inset;

}


.react-flow__node {
  border-radius: 12px !important;
  /*background-color:#FFE4E1;*/
}